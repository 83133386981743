import React from "react";
import "./salonprofilemodal.css";
import salon_logo from "./../../../../Assets/images/logos/Trakky logo purple.png";

const MemberShipModal = ({ data, handleClose, salon }) => {
  const handleBookNowBtn = () => {
    if (!salon?.name) {
      return;
    }
    // let link = `https://api.whatsapp.com/send?phone=916355167304&text=Can%20I%20know%20more%20about%20Offers%20%26%20salon%20services%20of%20${encodeURIComponent(
    //   salon?.name
    // )}%2C%20${encodeURIComponent(salon?.area)}%2C%20${encodeURIComponent(
    //   salon?.city
    // )}%3F`;

    let link = "";

    if (data?.whole_service) {
      let message = `Can you provide more details about the membership at ${salon?.name} in ${salon?.area}, ${salon?.city}? It comes with a discounted price of ${data?.price} rupees and includes all services.`;
       link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
        message
      )}`;

    } else {
      let message = `Can you provide more details about the membership at ${salon?.name} in ${salon?.area}, ${salon?.city}? It comes with a discounted price of ${data?.price} rupees and includes services like ${data?.service_data
        .map((item) => item?.service_name)
        .join(", ")}.`;
       link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
        message
      )}`;
    }


    window.open(link, "_blank");
  };

  return (
    <>
      <div className="Membership-Modal-main-container">
        <div className="membership-close-btn">
          <div className="button" onClick={handleClose}>
            X
          </div>
        </div>
        <div className="MSM-details">
          <div className="MSM-Title-header">Membership Details</div>
          <div className="MSM-trakky-x-salon">
            <img src={salon_logo} alt="" />
            <span>X</span>
            <span>{salon?.name}</span>
          </div>
          <div className="MSM-membership-meta-info">
            <div className="MSM-m-m-info-offer">
              <h4>Buy Membership for ₹{data?.price}</h4>
              <h3>
                {/* And Get Services Worth ₹8000 */}
                and get discount of {parseInt(data?.discount_percentage)}%
              </h3>
            </div>
            <div className="MSM-m-m-btn">
              <div>Show All Membership</div>
              <button onClick={handleBookNowBtn}>BUY MEMBERSHIP</button>
            </div>
          </div>
          <div className="MSM-membership-desc">
            {/* <ul>
              <li>Bridal packages included</li>
              <li>Hair cut included</li>
              <li>Services worth ₹8000</li>
              <li>Other T&Cs Applied</li>
            </ul> */}
            <h2 className=" font-semibold text-center text-[18px] mb-2 mt-0">
              Terms & conditions
            </h2>
            {data?.whole_service ? (
              <ul>
                <li>Get all services</li>
              </ul>
            ) : (
              <ul>
                {data?.service_data?.map((item, index) => (
                  <li key={index}>
                    <span></span>
                    {item?.service_name} included</li>
                ))}
              </ul>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: data?.term_and_conditions,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberShipModal;
