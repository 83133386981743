import React from "react";
import "./salonprofilemodal.css";
import { IoMdClose } from "react-icons/io";
import { ShareOutlined } from "@mui/icons-material";


const OfferSalonModal = ({ data, handleClose, salon , type }) => {

  if (!type) {
    return null;
  }

  const handleBookNowBtn = (name , price) => {
    if (!salon?.name) {
      return;
    }

    let message = `Can you provide more details about the special offer named '${name}' at ${salon?.name} in ${salon?.area}, ${salon?.city}? It comes with a discounted price of ${price} rupees.`

    let link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(message)}`;

    window.open(link, "_blank");
  };

  return (
    type === "offer" ? (
    <>
      <div className="Membership-Modal-main-container">
        <div className="membership-close-btn">
          <div className="button" onClick={handleClose}>
            <IoMdClose sx={{
              height: "30px",
              width: "30px",
            }}/>
          </div>
        </div>
        <div className="MSM-details">
          <div className="MSM-Title-header text-center !font-semibold">
            Offers Details
          </div>
          <div className="MSM-membership-meta-info">
            <div className=" h-28 w-auto rounded-md">
              <img src={data?.image} alt="" className=" h-28 w-auto rounded-md"/>
            </div>
            <div className="MSM-m-m-info-offer">
              <h2 className="text-[20px] font-semibold ">{data?.name}</h2>
            </div>
            <div className="flex justify-between OSM-price-cart">
              <div>
                <span className=" flex text-gray-500 text-sm pl-1">₹<del className=" !mx-0"><span>{data?.actual_price}</span></del></span>
                <div className="text-[18px] font-medium pl-1">
                  ₹{data?.discount_price} only
                </div>
              </div>
              <div className=" flex gap-2">
             
              <button
                onClick={() => {
                  handleBookNowBtn(data?.name, data?.discount_price);
                }}
              >
                Book Now
              </button>
              </div>
            </div>
          </div>
          <div className="MSM-membership-desc !pt-2">
            <h2 className=" font-semibold text-center text-[18px] mb-2 mt-0">
              Terms & conditions
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: data?.terms_and_conditions,
              }}
            />
          </div>
        </div>
      </div>
    </> ) : type === "main-offer" ? (
    <>
        <div className="Membership-Modal-main-container">
        <div className="membership-close-btn">
          <div className="button" onClick={handleClose}>
            <IoMdClose sx={{
              height: "30px",
              width: "30px",
            }}/>
          </div>
        </div>
        <div className="MSM-details">
          <div className="MSM-Title-header text-center !font-semibold">
            Offers Details
          </div>
          <div className="MSM-membership-meta-info">
            <div className=" h-28 w-auto rounded-md">
              <img src={data?.image} alt="" className=" h-28 w-auto rounded-md"/>
            </div>
            <div className="MSM-m-m-info-offer">
              <h2 className="text-[20px] font-semibold ">{data?.display_name}</h2>
              <p className="text-[14px] text-gray-500">{data?.display_sub_name}</p>
            </div>
            <div className="flex justify-between OSM-price-cart">
              {/* <div>
                <span className=" flex text-gray-500 text-sm pl-1">₹<del className=" !mx-0"><span>{data?.actual_price}</span></del></span>
                <div className="text-[18px] font-medium pl-1">
                  ₹{data?.discount_price} only
                </div>
              </div> */}
              <button
                onClick={() => {
                  // handleBookNowBtn(data?.name, data?.discount_price);
                }}
              >
                {/* Add to cart */}
                Book Now
              </button>
            </div>
          </div>
          {/* <div className="MSM-membership-desc !pt-2">
            <h2 className=" font-semibold text-center text-[18px] mb-2 mt-0">
              Available Services
            </h2>
            {
              data?.all_services ? (
                <ul >
                  <li>
                    Available in all services
                  </li>
                </ul>
              ) : (
                <ul>
                  {data?.services_details?.map((service) => (
                    <li key={service?.id}>
                      {service?.service_name}
                    </li>
                  ))}
                </ul>
              )

            }
          </div> */}

            
          <div className="MSM-membership-desc !pt-2">
            <h2 className=" font-semibold text-center text-[18px] mb-2 mt-0">
              Terms & conditions
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: data?.terms_and_conditions,
              }}
            />
          </div>
        </div>
      </div> 
    </>
    ) : null
  );
};

export default OfferSalonModal;
