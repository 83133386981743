import React from "react";
import { useState } from "react";
import "../ModalComponent/salonprofilemodal.css";
import "./offersalon.css";
import OfferSalonModal from "../ModalComponent/OfferSalonModal";
import Modal from "@mui/material/Modal";

const OfferSalon = ({
  salon,
  offerData,
  handleOfferOpen,
  handleModalClose,
  offerModalData,
  offerrModalOpen,
  mainOfferData,
  openModalType,
  setOpenModalType,
}) => {
  const [index, setIndex] = useState(0);
  return (
    <>
      <div className="N-Profile-page-offers">
        {mainOfferData?.length > 0 && mainOfferData?.map((item, index) => (
          <div
            className="N-Profile-page-offer-card"
            key={index}
            onClick={() => {
              // setOpenModalType();
              handleOfferOpen(item , "main-offer");
            }}
          >
            <div className="N-Profile-page-offer-card-img">
              <img src={item?.image} alt="" />
            </div>
          </div>
        )
        )}
        {offerData?.map((item, index) => (
          <div
            className="N-Profile-page-offer-card"
            key={index}
            onClick={() => {
              handleOfferOpen(item , "offer");
              // setOpenModalType();
            }}
          >
            <div className="N-Profile-page-offer-card-img">
              <img src={item?.image} alt="" />
            </div>
          </div>
        ))}
      </div>
      <Modal open={offerrModalOpen}>
        <OfferSalonModal
          open={offerrModalOpen}
          data={offerModalData}
          handleClose={() => {
            handleModalClose();
          }}
          salon={salon}
          type={openModalType}
        />
      </Modal>
    </>
  );
};

export default OfferSalon;
