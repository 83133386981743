import React, { useEffect } from "react";
import { useState } from "react";
import "./salonprofilemodal.css";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import CloseIcon from '@mui/icons-material/Close';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
  padding: "0px",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{
          padding: "0px",
          fontSize: "0.9rem",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  padding: "0px",
  borderBottom: "1px solid rgba(0, 0, 0, .125)",
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: "rotate(90deg)",
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles("dark", {
    backgroundColor: "rgba(255, 255, 255, .05)",
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
  padding: "8px 2px",
}));

const ServiceDetailsModal = ({
  salon,
  handleServiceDetailsClose,
  serviceId,
}) => {
  const [isViewMore, setIsViewMore] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(false);

  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleViewMore = () => {
    setIsViewMore(!isViewMore);
  };

  const getServiceDetails = async (servId) => {
    if (!servId) {
      return;
    }

    let url = `https://trakky.in:8000/salons/service-details/?service_id=${servId}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error("Failed to fetch service details");
      }
      const data = await response.json();
      console.log(data);
      setServiceDetails(data[0]);
    } catch (error) {
      console.error("Error fetching service details:", error.message);
    }
  };

  useEffect(() => {
    getServiceDetails(serviceId);
  }, [serviceId]);

  const [msg, setMsg] = useState("");

  const handleBookNowBtn = (item) => {
    // if (!props.salon?.name) {
    //   return;
    // }

    if (!item?.discount) {
      let message = `I want to book the ${item?.service_name} available at ${
        salon?.name
      } in ${salon?.area}, ${salon?.city}.
      
As it is mentioned, it comes with price of ${
        item?.price
      } rupees and takes ${formateTime(
        item?.service_time
      )} to complete the service.
      
Please book it for me.`;
      setMsg(message);
    } else {
      let message = `I want to book the ${item?.service_name} available at ${
        salon?.name
      } in ${salon?.area}, ${salon?.city}.
      
As it is mentioned, it comes with discounted price of ${
        item?.price
      } rupees and takes ${formateTime(
        item?.service_time
      )} to complete the service.
      
Please book it for me.`;
      setMsg(message);
    }

    let link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(
      msg
    )}`;

    window.open(link, "_blank");
  };

  const formateTime = (time) => {
    let str = "";

    if (time?.days) {
      str += time.days + " Days, ";
    }
    if (time?.seating) {
      str += time.seating + " Seating, ";
    }
    if (time?.hours) {
      str += time.hours + " Hours, ";
    }
    if (time?.minutes) {
      str += time.minutes + " Minutes, ";
    }

    str = str.slice(0, -2);

    return str;
  };

  return (  
    <div className="bg-white w-full mt-[10vh] sm:w-[min(400px,90%)] m-auto h-auto pb-4 rounded-t-xl sm:rounded-xl max-h-[90vh] overflow-auto sm:mt-5 relative">
     
      <div className="flex justify-between items-center px-5 z-50 sticky top-0 bg-white pt-4 pb-2">
        <div className=" h-full flex font-semibold items-center justify-start line-clamp-1">
          {serviceDetails?.service_info?.service_name}
        </div>
        <div className="SP-salon-close-btn !w-8 !h-8 !bg-white fixed flex items-center justify-center -translate-x-1/2 cursor-pointer top-4 sm:top-8 left-1/2 sm:left-[calc(50%+225px)] md:left-[calc(50%+225px)]" onClick={handleServiceDetailsClose}>
          <CloseIcon sx={{
            fontSize: "1rem",
          }} />
        </div>

        <button
        className="bg-[#512DC8] text-white py-1 px-2 text-sm rounded-md"
        onClick={()=>{handleBookNowBtn(serviceDetails?.service_info)}}
      >
        Book Now
      </button>
      </div>

      {(serviceDetails?.service_info?.salon_type == "PRIME" ||
        serviceDetails?.service_info?.salon_type == "LUXURIOUS") &&
        serviceDetails?.swiper_images?.length > 0 && (
          <>
            <div className=" h-[1px] w-full bg-gray-100 mt-2"></div>
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              navigation
            >
              {serviceDetails?.swiper_images?.map((item, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={item?.image}
                    alt="service-image"
                    className="w-full h-auto"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </>
        )}

      {serviceDetails?.description_image && (
        <>
          <div className=" h-[1px] w-full bg-gray-100 mt-2"></div>
          <div className=" w-full">
            <img
              src={serviceDetails?.description_image}
              alt="description-image"
              className="w-full h-auto"
            />
          </div>
        </>
      )}

      {serviceDetails?.service_info?.salon_type == "LUXURIOUS" &&
        serviceDetails?.benefit_meta_info_image && (
          <>
            <div className=" h-[8px] w-full bg-gray-100"></div>

            <div className="w-full">
              <img
                src={serviceDetails?.benefit_meta_info_image}
                alt="benefit-meta-info-image"
                className="w-full h-auto"
              />
            </div>
          </>
        )}

      {(serviceDetails?.service_info?.salon_type == "PRIME" ||
        serviceDetails?.service_info?.salon_type == "LUXURIOUS") &&
        serviceDetails?.key_ingredients && (
          <>
            <div className=" h-[8px] w-full bg-gray-100"></div>

            <div className=" w-full">
              <img
                src={serviceDetails?.key_ingredients}
                alt="key-ingredients"
                className="w-full h-auto"
              />
            </div>
          </>
        )}

      {(serviceDetails?.service_info?.salon_type == "PRIME" ||
        serviceDetails?.service_info?.salon_type == "LUXURIOUS") &&
        serviceDetails?.things_salon_use && (
          <>
            <div className=" h-[8px] w-full bg-gray-100"></div>

            <div className=" w-full">
              <img
                src={serviceDetails?.things_salon_use}
                alt="things-salon-use"
                className="w-full h-auto"
              />
            </div>
          </>
        )}

      <div className=" h-[8px] w-full bg-gray-100"></div>
      <div className=" flex flex-col gap-2 px-5 my-3">
        <h2 className=" font-semibold text-lg my-2">Overview</h2>
        <div className=" w-full grid-cols-3 grid gap-3">
          <div className=" aspect-square w-full bg-gray-100 rounded-md"></div>
          {serviceDetails?.overview_details?.slice(0, 6).map((item, index) => (
            <div className=" aspect-square w-full bg-gray-100 rounded-md">
              <img
                src={item?.image}
                alt="overview-image"
                className="w-full h-auto rounded-md object-cover"
              />
            </div>
          ))}
        </div>
      </div>

      {serviceDetails?.service_info?.salon_type == "LUXURIOUS" &&
        serviceDetails?.lux_exprience_image && (
          <>
            <div className=" h-[8px] w-full bg-gray-100"></div>

            <div className="w-full">
              <img
                src={serviceDetails?.lux_exprience_image}
                alt="lux-exprience-image"
                className="w-full h-auto"
              />
            </div>
          </>
        )}

      {serviceDetails?.steps && (
        <>
          <div className=" h-[8px] w-full bg-gray-100 my-3"></div>
          <div className=" flex flex-col gap-2 px-5 mb-3">
            <h2 className=" font-semibold text-lg my-2">How it works</h2>
            <div className=" w-full pl-4 flex flex-col gap-7 border-l-2 border-gray-200 ">
              {serviceDetails?.steps?.map((step, index) => (
                <div className="flex flex-col gap-2 relative" key={index}>
                  <p className=" text-[15px] text-gray-700">
                    {step?.instruction}
                  </p>
                  <div className=" w-full aspect-auto">
                    <img
                      src={step?.image}
                      alt="step-image"
                      className="w-full h-auto rounded-md"
                    />
                  </div>
                  <div className=" h-6 w-6 bg-gray-200 top-0 absolute -left-[30px] rounded-full border border-white leading-5 text-xs flex justify-center items-center text-gray-800">
                    {step?.step}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {serviceDetails?.service_info?.salon_type == "LUXURIOUS" &&
        serviceDetails?.aftercare_tips && (
          <>
            <div className=" h-[8px] w-full bg-gray-100"></div>

            <div className="w-full">
              <img
                src={serviceDetails?.aftercare_tips}
                alt="aftercare-tips"
                className="w-full h-auto"
              />
            </div>
          </>
        )}

      {(serviceDetails?.do_and_dont?.do?.length > 0 ||
        serviceDetails?.do_and_dont?.["don't"]?.length > 0) && (
        <>
          <div className=" h-[8px] w-full bg-gray-100 my-3"></div>
          <div className=" flex flex-col gap-2 px-5">
            <h2 className=" font-semibold text-lg my-2">Do's and dont's</h2>
            <div className=" w-full SD-do-desc">
              <ul>
                {serviceDetails?.do_and_dont?.do?.map((ite, index) => (
                  <li key={index}>{ite}</li>
                ))}
              </ul>
            </div>
            <div className=" w-full SD-dont-desc">
              <ul>
                {serviceDetails?.do_and_dont?.["don't"]?.map((ite, index) => (
                  <li key={index}>{ite}</li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
      {serviceDetails?.faqs?.length > 0 && (
        <>
          <div className=" h-[8px] w-full bg-gray-100 my-3"></div>
          <div className=" flex flex-col gap-2 px-5">
            <h2 className=" font-semibold text-lg my-2">
              Frequently asked questions
            </h2>
            <div className=" w-full ">
              {serviceDetails?.faqs?.map((faq, index) => (
                <Accordion
                  // expanded={expanded === "panel3"}
                  expanded={expanded === `panel${index}`}
                  // onChange={handleChange("panel3")}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        color: "black",
                      }}
                    >
                      {faq?.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq?.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceDetailsModal;
